/* Aerial org id dedicated for test usage in staging env */
export const STG_ORG_ID = 'aa8af3f2'

// Chart general data
export const CHART_HEIGHT = 285

// Signal quality & strength meters
export const BAND_VALID_COLOR = '#2bd82b'
export const BAND_INVALID_COLOR = '#dd2c2c'
export const REFRESH_SIGNAL_MS = 2500
export const REFRESH_SIGNAL_LONG_MS = 10000

// Motion status
export const ACCESSIBILITY_ANNOUNCEMENT_MS = 10000
export const REFRESH_MOTION_MS = 2500
export const SOFT_MAX_RAW_LEVEL = 400

// Client reported vs desired
export const REFRESH_CLIENT_MATCHES_MS = 2500

// Support default message
export const DEFAULT_ERROR_MESSAGE = 'Please contact Aerial support.'

// Email validation regex
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
