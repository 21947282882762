import { IStateUser, PoliciesRolesEnum } from '@/store/modules/user'

export const userPolicies = ['get_aud_thing_users', 'get_aud_user', 'get_iam_aud_user_policies']
export const managersPolicies = [...userPolicies, 'delete_iam_aud_user_policies', 'put_iam_aud_user_policies']

export default {
  policiesRole: (state: IStateUser) => {
    if (state.policies.includes('*')) {
      return PoliciesRolesEnum.Manager
    } else if (managersPolicies.every(policy => state.policies.includes(policy))) {
      return PoliciesRolesEnum.Manager
    } else if (userPolicies.every(policy => state.policies.includes(policy))) {
      return PoliciesRolesEnum.User
    } else {
      return null
    }
  }
}
