


































import Vue from 'vue'
import { mapMutations, mapState } from 'vuex'

import SelectOrg from '@/components/Select/Org.vue'
import { IStateUser } from '@/store/modules/user'
import { IStateDrawer } from '@/store/modules/drawer'
import { IStateOrg } from '@/store/modules/org'
import { IStateTheme } from '@/store/modules/theme'
import { redirectAuth0 } from '@/utils/auth'

export default Vue.extend({
  components: {
    SelectOrg
  },
  computed: {
    ...mapState<IStateDrawer>('drawer', {
      drawer: (state: IStateDrawer) => state.drawer,
      drawers: (state: IStateDrawer) => state.drawers
    }),
    ...mapState<IStateTheme>('theme', {
      dark: (state: IStateTheme) => state.dark
    }),
    ...mapState<IStateUser>('user', {
      user: (state: IStateUser) => state.user
    }),
    ...mapState<IStateOrg>('org', {
      org: (state: IStateOrg) => state.org.displayName,
      orgs: (state: IStateOrg) => state.orgs
    })
  },
  methods: {
    ...mapMutations('theme', ['switchTheme']),
    ...mapMutations('drawer', ['closeDrawers']),
    logout: function() {
      this.$store.commit('user/resetUser', {})
      this.$store.commit('user/resetPolicies')
      redirectAuth0('logout')
    }
  },
  watch: {
    dark(newValue) {
      localStorage.aerialDarkTheme = newValue
    }
  }
})
