import { IStateThing } from '@/store/modules/thing/index'
import { APIThing } from '@/type/things/thing'
import { opStatusMessage } from '@/utils/opStatus'

export default {
  opStatusI18nKey: (state: IStateThing) => {
    const opStatusCode = (state.thing as APIThing).reported.opStatus

    return opStatusMessage(opStatusCode)
  },

  sensitivityLevel: (state: IStateThing) => {
    if (state.thing === null) return

    const sensitivityLevel = state.thing.reported.motionAlgoSensitivity
    return sensitivityLevel ? sensitivityLevel : 0
  },

  sensitivityMatch: (state: IStateThing) => {
    if (state.thing === null) return

    const { reported, desired } = state.thing
    const sensitivityDesired = desired.motionAlgoSensitivity
    const sensitivityReported = reported.motionAlgoSensitivity

    return sensitivityDesired === sensitivityReported
  }
}
