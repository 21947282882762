import { IStatePolicy, IPolicies } from '@/store/modules/policy'

export default {
  setUserId(state: IStatePolicy, userId: string) {
    state.userId = userId
  },

  resetUserId(state: IStatePolicy) {
    state.userId = ''
  },

  setUserPolicies(state: IStatePolicy, userPolicies: IPolicies | number) {
    state.userPolicies = userPolicies
  },

  resetUserPolicies(state: IStatePolicy) {
    state.userPolicies = []
  },

  setDisplayMode(state: IStatePolicy, mode: string) {
    state.displayMode = mode
  },

  setFeedback(state: IStatePolicy, messageKey: string) {
    state.feedback = messageKey
  },

  resetFeedback(state: IStatePolicy) {
    state.feedback = ''
  }
}
