import { AxiosResponse } from 'axios'

import { DisplayModeEnum } from '@/store/modules/policy/index'
import { APIBaseResponse } from '@/type/baseresponse'
import { APIPolicies } from '@/type/iam'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getUserPolicies({ commit, state }: any) {
    const { userId } = state
    try {
      const response: AxiosResponse<APIPolicies> = await fetchApi.get(`/iam/aud/mgmt/user/${userId}/policies`)
      commit('policy/setUserPolicies', response.data.policies, { root: true })
    } catch (error) {
      if (error.response.data.code === 404) {
        commit('policy/setUserPolicies', error.response.data.code, { root: true })
      } else {
        sentryFetchError(error.response)
        commit('notification/addNotification', error.response, { root: true })
      }
    }
  },

  async putUserPolicies({ commit, state }: any, updatedPolicies: any) {
    const { userId } = state
    try {
      const response: AxiosResponse<APIBaseResponse> = await fetchApi.put(
        `/iam/aud/mgmt/user/${userId}/policies`,
        updatedPolicies
      )
      commit('policy/setFeedback', 'Card.Policies.Update200', { root: true })
      setTimeout(() => commit('policy/resetFeedback', undefined, { root: true }), 5000)
    } catch (error) {
      if (error.response.data.code === 400) {
        commit('policy/setFeedback', 'Card.Policies.Update400', { root: true })
      } else {
        sentryFetchError(error.response)
        commit('notification/addNotification', error.response, { root: true })
      }
    }
  },

  async deleteUserPolicies({ commit, state }: any) {
    const { userId } = state
    try {
      const response: AxiosResponse<APIBaseResponse> = await fetchApi.delete(
        `/iam/aud/mgmt/user/${userId}/policies`
      )
      commit('policy/setDisplayMode', DisplayModeEnum.Initial, { root: true })
      commit('policy/setFeedback', 'Card.Policies.DeletedPolicies', { root: true })
      setTimeout(() => commit('policy/resetFeedback', undefined, { root: true }), 5000)
    } catch (error) {
      if (error.response.data.code === 404) {
        commit('policy/setFeedback', 'Card.Policies.Deleted404', { root: true })
        setTimeout(() => commit('policy/resetFeedback', undefined, { root: true }), 5000)
      } else {
        sentryFetchError(error.response)
        commit('notification/addNotification', error.response, { root: true })
      }
    }
  }
}
