


































import Vue from 'vue'
import { mapState } from 'vuex'

import Error, { ErrorEnum } from '@/components/Error.vue'
import Header from '@/components/Layout/Header.vue'
import Layout from '@/components/Layout/Content.vue'
import Loader from '@/components/Loader.vue'
import { IStateUser, IErrorUser, IUser } from '@/store/modules/user'
import { IStateOrg } from '@/store/modules/org'
import { IStateTheme } from '@/store/modules/theme'
import { assertError, assertUser, parseB64, redirectAuth0 } from '@/utils/auth'
import { initSentry, setSentryUser } from '@/utils/sentry'

export default Vue.extend({
  data() {
    return {
      loading: false,
      error: {},
      errorEnum: ErrorEnum
    }
  },
  components: {
    Error,
    Header,
    Layout,
    Loader
  },
  computed: {
    ...mapState<IStateTheme>('theme', {
      dark: (state: IStateTheme) => state.dark
    }),
    ...mapState<IStateUser>('user', {
      authenticated: (state: IStateUser) => state.authenticated
    }),
    ...mapState<IStateOrg>('org', {
      orgs: (state: IStateOrg) => state.orgs
    })
  },
  methods: {
    getOrgs() {
      this.$store.dispatch('org/getOrgs').finally(() => {
        this.$store.dispatch('user/getPolicies')
        this.loading = false
      })
    },
    logout() {
      redirectAuth0('logout')
    }
  },
  mounted() {
    this.loading = true

    const urlParams = new URLSearchParams(window.location.search)
    const userInfoParams = urlParams.get('userInfo')
    const userErrorParams = urlParams.get('errorInfo')

    const errorInfo: IErrorUser | {} = parseB64(userErrorParams)
    const isError = assertError(errorInfo)
    if (isError) {
      this.$store.commit('user/resetAuth')
      this.$store.commit('user/resetUser')
      this.error = errorInfo
      return
    }

    const userInfo: IUser | {} = parseB64(userInfoParams)
    const isAuth = assertUser(userInfo)
    if (!isAuth) {
      this.$store.commit('user/resetAuth')
      this.$store.commit('user/resetUser')
      return redirectAuth0('login')
    }

    if ((userInfo as IUser).email === 'test.dashboard@aerial.ai') {
      initSentry()
      setSentryUser(userInfo as IUser)
    }

    this.$store.commit('user/setAuth', true)
    this.$store.commit('user/setUser', userInfo)
    window.history.replaceState(null, '', '/')

    this.getOrgs()

    if (localStorage.aerialDarkTheme === 'false') this.$store.commit('theme/switchTheme')
  }
})
