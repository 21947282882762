import actions from '@/store/modules/policy/actions'
import mutations from '@/store/modules/policy/mutations'

export enum DisplayModeEnum {
  Editing = 'editing',
  Initial = 'initial',
  Reading = 'reading'
}

export const state: IStatePolicy = {
  displayMode: DisplayModeEnum.Initial,
  feedback: '',
  userId: '',
  userPolicies: []
}

export const policy = {
  namespaced: true,
  state,
  mutations,
  actions
}

export interface IStatePolicy {
  displayMode: string
  feedback: string
  userId: string
  userPolicies: IPolicies | number
}

export type IPolicies = {
  operations: string[]
  resources: string[]
}[]
