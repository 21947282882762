import Vue from 'vue'
import VueI18n, { DateTimeFormats, LocaleMessages } from 'vue-i18n'

const DEFAULT_LANG = localStorage.aerialLang ? localStorage.aerialLang : 'en'

Vue.use(VueI18n)

export default new VueI18n({
  locale: DEFAULT_LANG,
  fallbackLocale: DEFAULT_LANG,
  messages: defineLocale().localeMessage,
  dateTimeFormats: defineLocale().localeDateTime
})

function defineLocale(): ILocales {
  // Get locale translation
  const localesString = require.context('@/locales/string', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const localesDate = require.context('@/locales/date', true, /[A-Za-z0-9-_,\s]+\.json$/i)

  const localeMessage: LocaleMessages = {}
  const localeDateTime: DateTimeFormats = {}

  localesString.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      localeMessage[locale] = localesString(key)
    }
  })

  localesDate.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      localeDateTime[locale] = localesDate(key)
    }
  })

  return { localeMessage, localeDateTime }
}

interface ILocales {
  localeMessage: LocaleMessages
  localeDateTime: DateTimeFormats
}
