import actions from '@/store/modules/user/actions'
import mutations from '@/store/modules/user/mutations'
import getters from '@/store/modules/user/getters'

export const state: IStateUser = {
  authenticated: false,
  user: null,
  policies: []
}

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}

// Types
export enum PoliciesRolesEnum {
  Manager = 'manager',
  User = 'user'
}

export interface IStateUser {
  authenticated: boolean
  user: IUser | null
  policies: string[]
}

export interface IErrorUser {
  code: number
  message: string
  url: string
}

export interface IUser {
  email: string
  /** Expiration, as an epoch timestamp in seconds */
  exp?: number
  name?: string
  nickname?: string
}
