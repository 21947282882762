import { AxiosResponse } from 'axios'

import { APIPolicies } from '@/type/iam'
import fetchApi from '@/utils/axios'
import { sentryFetchError } from '@/utils/sentry'

export default {
  async getPolicies({ commit, state }: any) {
    try {
      const response: AxiosResponse<APIPolicies> = await fetchApi.get(
        `/iam/aud/mgmt/user/${state.user.email}/policies`
      )
      commit('user/setPolicies', response.data.policies, { root: true })
    } catch (error) {
      sentryFetchError(error.response)
      commit('notification/addNotification', error.response, { root: true })
    }
  }
}
